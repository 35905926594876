<template>
  <default-layout>
    <div class="lecturer__container">
      <Lecturer
        v-for="lecturer in lecturers[$i18n.locale]"
        :key="lecturer.id"
        :lecturer="lecturer"
        @click="changeRoute(`/lecturer/${lecturer.id}`)"
      ></Lecturer>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layout/DefaultLayout";
import Lecturer from "@/components/Lecturer";
export default {
  data() {
    return {
      lecturers: [],
    };
  },
  methods: {},
  async created() {
    this.$swal({
      title: "Loading",
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 10000,
      onOpen: () => {
        this.$swal.showLoading();
      },
    });
    try {
      const response = await this.$axios.get("/api/lecturer");
      this.lecturers = response.data;
      this.$swal.close();
    } catch (err) {
      this.$swal.close();
    }
  },
  components: {
    DefaultLayout,
    Lecturer,
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/lecturer.scss";
</style>