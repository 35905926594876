<template>
  <div class="lecturer__card" @click="changeRoute(`/lecturer/${lecturer.id}`)">
    <div
      class="lecturer__picture"
      :style="{ backgroundImage: 'url(' + lecturer.picture + ')' }"
    ></div>
    <h3 class="lecturer__name">{{ lecturer.name }}</h3>
    <p class="lecturer__title">
      {{ lecturer.academic_title }} - {{ lecturer.position }}
    </p>
    <a class="lecturer__email">{{ lecturer.email }}</a>
  </div>
</template>

<script>
export default {
  props: {
    lecturer: {
      type: Object,
      required: true,
    },
  },
  methods: {
    changeRoute(path) {
      console.log(path);
      this.$router.push(path);
    },
  },
};
</script>

<style>
</style>